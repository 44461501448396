<template>
  <div class="contract-detail wrapper">
    <div class="container relative">
      <section class="container-header">
        <div
          :class="[
            'subcontainer',
            'subcontainer-large',
            'flex',
            'flex-row',
            'justify-between',
            'items-end'
          ]"
        >
          <!-- Bouton annuler/retour -->
          <button
            class="btn btn-small btn-outline--primary-bluer inline-flex"
            :style="{ maxHeight: '20px' }"
            @click="$router.push({ name: 'contracts' })"
          >
            {{ context === 'CREATE-CONTRACT' || context === 'CREATE-AMENDMENT' ? 'Annuler' : 'Retour' }}
          </button>
           <div :class="['flex', 'flex-col', 'justify-center', 'items-center']">
            <!-- Title -->
            <div class="container-header--title" :style="{ marginBottom: '0' }">
              <h1>
                {{
                  context === 'CREATE-CONTRACT'
                  ? '+ Ajout d\'un contrat'
                  : context === 'CREATE-AMENDMENT'
                  ? '+ Ajout d\'un avenant'
                  : header.title
                }}
              </h1>
            </div>
            <!-- Lien Parentalité, visible uniquement sur la création d'un avenant et si hasChildrens -->
            <div
              v-if="(context === 'VIEW' || context === 'UPDATE-CONTRACT' || context === 'UPDATE-AMENDMENT') && (header.hasParent || header.hasChildrens)"
              :class="['flex', 'flex-row', 'items-center', 'cursor-pointer']"
              :style="{ marginTop: '1rem' }"
              @click="isParentModalOpen = true"
            >
              <div :class="['contract-parent--background']">
                <icon
                  icon="parentalite"
                  size="sm"
                />
              </div>
              <span :class="['text-primary-bluer', 'ml-2', 'underline']">
                {{
                  header.amendment
                  ? 'Avenant de : ' + header.parentContractTitle
                  : 'Parentalité du contrat'
                }}
              </span>
            </div>
           </div>

          <!-- Bouton 'Historique', visible uniquement sur un contrat existant -->
          <button
            v-if="context === 'VIEW' || context === 'UPDATE-CONTRACT' || context === 'UPDATE-AMENDMENT'"
            class="btn btn-small btn-outline--primary-bluer inline-flex"
            :style="{ maxHeight: '20px' }"
            @click="isHistoryModalOpen = true"
          >
            <icon
              icon="historique"
              size="xs"
              :class="['mr-2']"
            />
            Historique
          </button>
          <div v-else></div>
        </div>
      </section>
    </div>

    <div class="background">
      <div :class="['subcontainer-large', 'flex-col']">
        <div :class="['contracts-listing__table', 'mt-6']">
          <!-- Nav Modifier / Poursuite / Suppression -->
          <nav
            v-if="context === 'VIEW' && hasWritingRights"
            :class="['flex', 'flex-row', 'items-center', 'justify-center', 'mb-6']"
          >
            <div
              @click="originalContractStatus === 'archived' ? null : toggleEditCtx(header.amendment ? 'UPDATE-AMENDMENT' : 'UPDATE-CONTRACT')"
              :class="['flex', 'flex-row', 'cursor-pointer', 'mx-5']"
            >
              <icon
                icon="modifier"
                size="sm"
                :style="originalContractStatus === 'archived' ? { opacity: '0.5'} : null"
              />
              <span
                :class="['ml-2', 'underline']"
                :style="originalContractStatus === 'archived' ? { opacity: '0.5'} : null"
              >
                Modifier
              </span>
            </div>
            <div
              :class="['flex', 'flex-row', 'cursor-pointer', 'mx-5',]"
              @click="originalContractStatus === 'archived' ? null : isPursuitModalOpen = true"
            >
              <icon
                icon="poursuivre-contrat"
                size="sm"
                :style="originalContractStatus === 'archived' ? { opacity: '0.5'} : null"
              />
              <span
                :class="['ml-2', 'underline']"
                :style="originalContractStatus === 'archived' ? { opacity: '0.5'} : null"
              >
                Poursuivre le contrat
              </span>
            </div>
            <div :class="['flex', 'flex-row', 'cursor-pointer', 'mx-5']" @click="isDeleteModalOpen = true">
              <icon
                icon="supprimer"
                size="sm"
              />
              <span :class="['ml-2', 'underline']">
                Supprimer
              </span>
            </div>
          </nav>

          <!-- ------- -->
          <!-- CONTENT -->
          <!-- ------- -->
          <card>
            <form @submit.prevent :class="['pb-10']">
              <!-- INFORMATIONS GÉNÉRALES -->
              <section :class="['relative']">
                <header
                  :class="['card-header', 'flex', 'flex-col', 'px-8', 'md:flex-row', 'items-center', 'p-6', 'justify-center']"
                  :style="{ boxShadow: '0 0 0 1px rgba(0, 0, 0, 0.05)' }"
                >
                  <h2 :class="['m-0-auto', 'text-secondary', 'font-bold']">
                    Informations générales
                  </h2>
                  <span
                    v-if="header.author"
                    :class="['font-normal', 'absolute']" :style="{ right: '26px' }"
                  >
                    Auteur: {{ header.author }}
                  </span>
                </header>
                <fieldset :class="['grid', 'grid-cols-1', 'md:grid-cols-2', 'gap-8', 'mt-4', 'mx-8']">
                  <!-- Status -->
                  <div :class="['flex', 'flex-col']">
                    <label
                      for="status"
                      :class="['form-label', 'font-bold', errors.contractStatus ? 'text-error' : null]"
                    >
                      Statut<span v-if="context !== 'VIEW'">*</span>
                    </label>
                    <m-select
                      :options="dropdownStatuses"
                      :disabled="context === 'VIEW'"
                      :error="errors.contractStatus"
                      :ref="'contractStatus'"
                      v-model="form.contractStatus"
                      @input="handleContractStatus"
                    />
                  </div>

                  <!-- Title -->
                  <div class="flex flex-col relative">
                    <label
                      for="title"
                      :class="['form-label', 'font-bold', errors.title ? 'text-error' : null]"
                    >
                      Titre<span v-if="context !== 'VIEW'">*</span>
                    </label>
                    <input
                      id="title"
                      name="title"
                      v-model="form.title"
                      type="text"
                      maxlength="85"
                      :class="[
                        'form-input',
                        context === 'VIEW' ? 'disabled' : null,
                        errors.title ? 'error' : null
                      ]"
                      :disabled="context === 'VIEW'"
                    />
                    <div v-show="errors.title" :class="['absolute', 'text-sm']" :style="{ color: 'red', bottom: '-8px' }">
                      {{ errors.title }}
                    </div>
                  </div>

                  <!-- Cadre et Contrat Parent -->
                  <div :class="['flex', 'flex-col', 'justify-between']">
                    <!-- Cadre -->
                    <div :class="['flex', 'flex-col', 'relative']">
                      <label
                        for="cadre"
                        :class="['form-label', 'font-bold', 'flex', errors.masterContract ? 'text-error' : null]"
                      >
                        <span>S’agit-il d’un contrat cadre ?<span v-if="context !== 'VIEW'">*</span></span>
                        <icon
                          icon="infos-primary-bluer"
                          size="md"
                          v-tooltip.top="'Le contrat cadre est un accord par lequel les parties conviennent des caractéristiques générales de leurs relations contractuelles futures. Des contrats d\'application en précisent les modalités d\'exécution.'"
                          :style="{ cursor: 'pointer' }"
                          :class="['ml-3']"
                        />
                      </label>
                      <span v-if="context === 'VIEW'">
                        {{ form.masterContract ? 'Oui' : 'Non' }}
                      </span>
                      <div v-else :class="'relative'">
                        <div :class="['flex', 'flex-row']">
                          <div :class="['p-1', 'pr-5', 'flex', 'flex-row', 'items-center']">
                            <input
                              :value="true"
                              :id="true"
                              :disabled="context === 'CREATE-AMENDMENT' || context === 'UPDATE-AMENDMENT'"
                              v-model="form.masterContract"
                              name="radio__cadreContract"
                              type="radio"
                            />
                            <label :for="'radio__cadreContract'" :class="['px-1']">
                              Oui
                            </label>
                          </div>
                          <div :class="['p-1', 'pl-5', 'flex', 'flex-row', 'items-center']">
                            <input
                              :value="false"
                              :id="false"
                              :disabled="context === 'CREATE-AMENDMENT' || context === 'UPDATE-AMENDMENT'"
                              v-model="form.masterContract"
                              name="radio__cadreContract"
                              type="radio"
                            />
                            <label :for="'radio__cadreContract'" :class="['px-1']">
                              Non
                            </label>
                          </div>
                        </div>
                      </div>
                      <div v-show="errors.masterContract" :class="['absolute', 'text-sm']" :style="{ color: 'red', bottom: '-12px' }">
                        {{ errors.masterContract }}
                      </div>
                    </div>
                    <!-- Contrat parent -->
                    <div :class="['flex', 'flex-col']">
                      <label
                        for="parent-contract"
                        :class="['form-label', 'font-bold', errors.parentContract ? 'text-error' : null]"
                      >
                        Contrat parent
                      </label>
                      <span v-show="context === 'VIEW' && form.parentContract">
                        {{ parentContractLabel }}
                      </span>
                      <autosuggest
                        ref="parentContract"
                        v-show="context !== 'VIEW'"
                        :disabled="form.masterContract === null || form.masterContract === true || context === 'CREATE-AMENDMENT' || context === 'UPDATE-AMENDMENT'"
                        :suggestions="[{ data: dropdownContracts }]"
                        :error="errors.parentContract"
                        @select="handlerParentContract"
                      />
                    </div>
                  </div>

                  <!-- Description -->
                  <div class="flex flex-col relative">
                    <label
                      for="description"
                      :class="['form-label', 'font-bold', errors.description ? 'text-error' : null]"
                    >
                      Description
                    </label>
                    <textarea
                      id="description"
                      name="description"
                      v-model="form.description"
                      rows="5"
                      type="textfield"
                      :class="[
                        'form-input',
                        context === 'VIEW' ? 'disabled' : null,
                        errors.description ? 'error' : null
                      ]"
                      :disabled="context === 'VIEW'"
                    >
                    </textarea>
                    <div v-show="errors.description" :class="['absolute', 'text-sm']" :style="{ color: 'red', bottom: '-8px' }">
                      {{ errors.description }}
                    </div>
                  </div>

                  <!-- Documents contractuels (contrats et annexes)  -->
                  <div class="flex flex-col relative">
                    <label
                      for="documents"
                      :class="['form-label', 'font-bold', 'flex', errors.contractDocuments ? 'text-error' : null]"
                    >
                      <span>Documents contractuels (contrats et annexes)<span v-if="context !== 'VIEW'">*</span></span>
                      <icon
                          icon="infos-primary-bluer"
                          size="md"
                          v-tooltip.top="'Cliquer sur « Ajouter une ou des pièces jointes » vous permet d’accéder à vos dossiers informatiques et sélectionner le ou les fichiers que vous souhaitez ajouter à votre liste de contrats.'"
                          :class="['ml-3']"
                          :style="{ cursor: 'pointer' }"
                        />
                    </label>
                    <uploader v-if="context !== 'VIEW'" @upload="addContractDocument" />
                    <template v-for="attachment in form.contractDocuments">
                      <div :key="attachment.fileId">
                        <div
                          v-if="!attachment.documentSlug"
                          :class="['relative', 'flex', 'flex-row', 'items-center']"
                          :style="{ padding: '0', width: 'fitContent', cursor: 'auto'}"
                        >
                        <span :class="['text-primary', 'underline', 'flex']" :style="{ opacity: '.5'}">
                          <span>{{ attachment.filename }}</span>
                        </span>
                        <button
                          v-show="context !== 'VIEW'"
                          :class="['mx-5']"
                          @click="deleteItem('contractDocuments', attachment)"
                        >
                          <img
                            src="@/assets/images/icons/fermer-primary-bluer.svg"
                            alt="pièce_jointe"
                            width="11"
                          />
                        </button>
                      </div>
                      <div
                        v-else
                        :class="['relative', 'flex', 'flex-row', 'items-center']"
                        :style="{ padding: '0', width: 'fitContent'}"
                      >
                        <button
                          @click="downloadFile(attachment.documentSlug)"
                          :style="{ padding: '0', width: 'fitContent'}"
                        >
                          <span :class="['text-primary', 'underline', 'flex']">
                            <span>{{ attachment.filename }}</span>
                          </span>
                        </button>
                        <button
                          v-show="context !== 'VIEW'"
                          :class="['mx-5']"
                          @click="deleteItem('contractDocuments', attachment)"
                        >
                          <img
                            src="@/assets/images/icons/fermer-primary-bluer.svg"
                            alt="pièce_jointe"
                            width="11"
                          />
                        </button>
                      </div>
                      </div>
                    </template>
                    <div v-if="errors.contractDocuments" :class="['absolute', 'text-sm']" :style="{ color: 'red', bottom: '-8px' }">
                      {{ errors.contractDocuments }}
                    </div>
                  </div>

                  <!-- Collaborateur(s) assigné(s) au contrat  -->
                  <div class="flex flex-col">
                    <label
                      for="collaborators"
                      :class="['form-label', 'font-bold', 'flex', errors.collaborators ? 'text-error' : null]"
                    >
                      <span>
                        Collaborateur(s) assigné(s) au contrat
                      </span>
                      <icon
                          icon="infos-primary-bluer"
                          size="md"
                          v-tooltip.top="'Seul un collaborateur habilité en lecture et/ou en écriture des contrats peut être ajouté à la liste qui fera apparaître ses prénom et nom et son statut (ses droits).'"
                          :style="{ cursor: 'pointer' }"
                          :class="['ml-3']"
                        />
                    </label>
                    <autosuggest
                      v-show="context !== 'VIEW'"
                      :suggestions="[{ data: dropdownCollaborators }]"
                      :error="errors.collaborators"
                      @select="addCollaborator"
                    />
                    <template v-for="(collaborator, index) in form.collaborators">
                      <div
                        :key="index"
                        :class="['relative', 'flex', 'flex-row', 'items-center']"
                        :style="{ padding: '0', width: 'fitContent'}"
                      >
                        <img
                          v-if="collaborator.permission === 'write'"
                          src="@/assets/images/icons/modifier.svg"
                          alt="pièce_jointe"
                          :style="{ marginRight: '5px'}"
                          width="11"
                        />
                        <img
                          v-else
                          src="@/assets/images/icons/contact.svg"
                          alt="pièce_jointe"
                          :style="{ marginRight: '5px'}"
                          width="11"
                        />
                        <button
                          :disabled="context === 'VIEW'"
                          @click="openPopinCollaboratorPermission(collaborator)"
                          :style="context === 'VIEW' ? { padding: '0', width: 'fitContent', cursor: 'auto', opacity: '.5'} : { padding: '0', width: 'fitContent'}"
                        >
                          <span :class="['text-primary', 'underline', 'flex']">
                            <span>{{ collaborator.permission === 'write' ? 'Écriture' : 'Lecture' }} - {{ collaborator.name }}</span>
                          </span>
                        </button>
                        <button
                          v-show="context !== 'VIEW'"
                          :class="['mx-5']"
                          @click="deleteItem('collaborator', collaborator)"
                        >
                          <img
                            src="@/assets/images/icons/fermer-primary-bluer.svg"
                            alt="pièce_jointe"
                            width="11"
                          />
                        </button>
                      </div>
                    </template>
                  </div>

                  <!-- Pièces jointes privées, non incluses dans le contrat  -->
                  <div class="flex flex-col">
                    <label
                      for="pj"
                      :class="['form-label', 'font-bold']"
                    >
                      Pièces jointes privées, non incluses dans le contrat
                    </label>
                    <uploader v-if="context !== 'VIEW'" @upload="addPrivateAttachment" />
                    <template v-for="attachment in form.privateAttachments">
                      <div :key="attachment.fileId">
                        <div
                        v-if="!attachment.documentSlug"
                        :class="['relative', 'flex', 'flex-row', 'items-center']"
                        :style="{ padding: '0', width: 'fitContent', cursor: 'auto'}"
                      >
                        <span :class="['text-primary', 'underline', 'flex']" :style="{ opacity: '.5'}">
                          <span>{{ attachment.filename }}</span>
                        </span>
                        <button
                          v-show="context !== 'VIEW'"
                          :class="['mx-5']"
                          @click="deleteItem('privateAttachments', attachment)"
                        >
                          <img
                            src="@/assets/images/icons/fermer-primary-bluer.svg"
                            alt="pièce_jointe"
                            width="11"
                          />
                        </button>
                      </div>
                      <div
                        v-else
                        :class="['relative', 'flex', 'flex-row', 'items-center']"
                        :style="{ padding: '0', width: 'fitContent'}"
                      >
                        <button
                          @click="downloadFile(attachment.documentSlug)"
                          :style="{ padding: '0', width: 'fitContent'}"
                        >
                          <span :class="['text-primary', 'underline', 'flex']">
                            <span>{{ attachment.filename }}</span>
                          </span>
                        </button>
                        <button
                          v-show="context !== 'VIEW'"
                          :class="['mx-5']"
                          @click="deleteItem('privateAttachments', attachment)"
                        >
                          <img
                            src="@/assets/images/icons/fermer-primary-bluer.svg"
                            alt="pièce_jointe"
                            width="11"
                          />
                        </button>
                      </div>
                      </div>
                    </template>
                  </div>

                  <div class="flex flex-col"></div>

                  <!-- Nature du contrat -->
                  <div class="flex flex-col">
                    <label
                      for="nature"
                      :class="['form-label', 'font-bold', errors.contractNature ? 'text-error' : null]"
                    >
                      <span>Nature du contrat<span v-if="context !== 'VIEW'">*</span></span>
                    </label>
                    <m-select
                      :options="dropdownContractNatures"
                      :disabled="context === 'VIEW' || context === 'CREATE-AMENDMENT' || context === 'UPDATE-AMENDMENT'"
                      :error="errors.contractNature"
                      :ref="'contractNature'"
                      :value-key="'id'"
                      v-model="form.contractNature"
                      @input="handleContractNature"
                    />
                  </div>

                  <!-- Univers d'achat  -->
                  <div class="flex flex-col">
                    <label
                      for="purchase-category"
                      :class="['form-label', 'font-bold', errors.purchaseCategory ? 'text-error' : null]"
                    >
                      <span>Univers d’achat<span v-if="context !== 'VIEW'">*</span></span>
                    </label>

                    <m-select
                      :options="dropdownPurchaseCategories"
                      :disabled="context === 'VIEW' || context === 'CREATE-AMENDMENT' || context === 'UPDATE-AMENDMENT'"
                      :ref="'purchaseCategory'"
                      :error="errors.purchaseCategory"
                      :value-key="'id'"
                      v-model="form.purchaseCategory"
                      @input="handlePurchaseCategory"
                    />
                  </div>

                  <!-- Réseau Mercurial -->
                  <div :class="['flex', 'flex-col', 'relative']">
                    <label
                      for="mercunetwork"
                      :class="['form-label', 'font-bold', 'flex', errors.mercurialNetwork ? 'text-error' : null]"
                    >
                      <span>Ce contrat est-il lié au réseau Mercurial ?<span v-if="context !== 'VIEW'">*</span></span>
                      <icon
                          icon="infos-primary-bluer"
                          size="md"
                          v-tooltip.top="' En cliquant sur « Oui », votre contrat est signé avec un fournisseur référencé par MERCURIAL aux conditions négociées par MERCURIAL.'"
                          :class="['ml-3']"
                          :style="{ cursor: 'pointer' }"
                        />
                    </label>
                    <span v-if="context === 'VIEW'">
                      {{ form.mercurialNetwork ? 'Oui' : 'Non' }}
                    </span>
                    <div v-else :class="'relative'">
                      <div :class="['flex', 'flex-row']">
                        <div :class="['p-1', 'pr-5', 'flex', 'flex-row', 'items-center']">
                          <input
                            :value="true"
                            :id="true"
                            :disabled="context === 'CREATE-AMENDMENT' || context === 'UPDATE-AMENDMENT'"
                            v-model="form.mercurialNetwork"
                            name="radio__mercurialNetwork"
                            type="radio"
                          />
                          <label :for="'radio__mercurialNetwork'" :class="['px-1']">
                            Oui
                          </label>
                        </div>
                        <div :class="['p-1', 'pl-5', 'flex', 'flex-row', 'items-center']">
                          <input
                            :value="false"
                            :id="false"
                            :disabled="context === 'CREATE-AMENDMENT' || context === 'UPDATE-AMENDMENT'"
                            v-model="form.mercurialNetwork"
                            name="radio__mercurialNetwork"
                            type="radio"
                          />
                          <label :for="'radio__mercurialNetwork'" :class="['px-1']">
                            Non
                          </label>
                        </div>
                      </div>
                    </div>
                    <div v-show="errors.mercurialNetwork" :class="['absolute', 'text-sm']" :style="{ color: 'red', bottom: '-12px' }">
                      {{ errors.mercurialNetwork }}
                    </div>
                  </div>

                  <!-- Fournisseur -->
                  <div class="flex flex-col">
                    <label
                      for="supplierText"
                      :class="['form-label', 'font-bold', errors.supplier ? 'text-error' : null]"
                    >
                    Fournisseur
                    </label>
                    <template v-if="userRole === 'subscriber' && form.mercurialNetwork">
                      <span v-show="context === 'VIEW' && form.supplier">
                        {{ supplierLabel }}
                      </span>
                      <autosuggest
                        ref="supplier"
                        v-show="context !== 'VIEW'"
                        :disabled="context === 'VIEW' || form.mercurialNetwork === null || context === 'CREATE-AMENDMENT' || context === 'UPDATE-AMENDMENT'"
                        :suggestions="[{ data: dropdownSuppliers }]"
                        :error="errors.supplier"
                        @select="handlerSupplierItem"
                      />
                    </template>
                    <template v-else>
                      <input
                        id="supplierText"
                        name="supplierText"
                        v-model="form.supplierText"
                        type="text"
                        :disabled="context === 'VIEW' || form.mercurialNetwork === null || context === 'CREATE-AMENDMENT' || context === 'UPDATE-AMENDMENT'"
                        :class="['form-input', context === 'VIEW' ||  form.mercurialNetwork === null ? 'disabled' : null]"
                        :error="errors.supplierText"
                      />
                    </template>
                  </div>
                </fieldset>
                <br><br>
              </section>

              <!-- DATES ET ÉCHÉANCES -->
              <section>
                <header
                  class="card-header flex flex-col px-8 md:flex-row items-center p-6 justify-center"
                  :style="{ boxShadow: '0 0 0 1px rgba(0, 0, 0, 0.05)' }"
                >
                  <h2
                    :class="['m-0-auto', 'text-secondary', 'font-bold']"
                  >
                    Dates et échéances
                  </h2>
                </header>
                <fieldset class="grid grid-cols-1 md:grid-cols-2 gap-8 mt-4 mx-8">
                  <!-- Date d'application -->
                  <div class="flex flex-col relative">
                    <label
                      for="startDate"
                      :class="['form-label', 'font-bold', 'flex', errors.startDate ? 'text-error' : null]"
                    >
                      <span>Date d’application<span v-if="context !== 'VIEW'">*</span></span>
                      <icon
                          icon="infos-primary-bluer"
                          size="md"
                          v-tooltip.top="'La date d’application de votre contrat correspond à la date d’entrée en vigueur ou de prise d’effet des conditions contractuelles.'"
                          :class="['ml-3',  errors.startDate ? 'text-error' : null]"
                          :style="{ cursor: 'pointer' }"
                        />
                    </label>
                    <Calendar
                      v-model="form.startDate"
                      show-icon
                      placeholder="Date d’application"
                      :class="['w-full', errors.startDate ? 'calendar-error' : null, isStartDateDisabled ? 'calendar-disabled' : null]"
                      :disabled="isStartDateDisabled"
                      dateFormat="dd/mm/yy"
                      showButtonBar
                    />
                    <div v-show="errors.startDate" :class="['absolute', 'text-sm']" :style="{ color: 'red', bottom: '-18px' }">
                      {{ errors.startDate }}
                    </div>
                  </div>

                  <!-- Date de fin -->
                  <div class="flex flex-col relative">
                    <label
                      for="endDate"
                      :class="['form-label', 'font-bold', 'flex', errors.endDate ? 'text-error' : null]"
                    >
                      <span>Date de fin<span v-if="context !== 'VIEW'">*</span></span>
                      <icon
                          icon="infos-primary-bluer"
                          size="md"
                          v-tooltip.top="'La date de fin de contrat correspond au moment où le contrat cesse de s’appliquer définitivement ou à la fin d’une période s’il est prévu une tacite reconduction dans votre contrat.'"
                          :class="['ml-3',  errors.endDate ? 'text-error' : null]"
                          :style="{ cursor: 'pointer' }"
                        />
                    </label>
                    <Calendar
                      v-model="form.endDate"
                      show-icon
                      placeholder="Date de fin"
                      :class="['w-full', errors.endDate ? 'calendar-error' : null, isEndDateDisabled ? 'calendar-disabled' : null]"
                      :disabled="isEndDateDisabled"
                      dateFormat="dd/mm/yy"
                      showButtonBar
                    />
                    <div v-show="errors.endDate" :class="['absolute', 'text-sm']" :style="{ color: 'red', bottom: '-18px' }">
                      {{ errors.endDate }}
                    </div>
                  </div>

                  <!-- Durée de préavis en jour -->
                  <div class="flex flex-col relative">
                    <label
                      for="noticeDuration"
                      :class="['form-label', 'font-bold', 'flex', errors.noticeDuration ? 'text-error' : null]"
                    >
                      <span>Durée de préavis en jours<span v-if="context !== 'VIEW'">*</span></span>
                      <icon
                          icon="infos-primary-bluer"
                          size="md"
                          v-tooltip.top="'La durée du préavis, ici exprimée en jours, est le délai minimal à respecter pour mettre fin au contrat.'"
                          :class="['ml-3']"
                          :style="{ cursor: 'pointer' }"
                        />
                    </label>
                    <input
                      id="noticeDuration"
                      name="noticeDuration"
                      v-model="form.noticeDuration"
                      type="number"
                      placeholder="Saisir une valeur en jours"
                      min="10"
                      @input="checkPositive"
                      :class="[
                        'form-input',
                        isNoticeDurationDisabled ? 'disabled' : null,
                        errors.noticeDuration ? 'error' : null
                      ]"
                      :disabled="isNoticeDurationDisabled"
                    />
                    <div v-show="errors.noticeDuration" :class="['absolute', 'text-sm']" :style="{ color: 'red', bottom: '-8px' }">
                      {{ errors.noticeDuration }}
                    </div>
                  </div>

                  <!-- Date de prévenance (notification) -->
                  <div class="flex flex-col relative">
                    <label
                      for="notificationDate"
                      :class="['form-label', 'font-bold', 'flex', errors.endDate ? 'text-error' : null]"
                    >
                      <span>Date de prévenance (notification)<span v-if="context !== 'VIEW'">*</span></span>
                      <icon
                        icon="infos-primary-bluer"
                        size="md"
                        v-tooltip.top="'Nous vous invitons à saisir une date de prévenance avec un délai minimal de 30 jours avant le démarrage de la période de préavis, pour vous prévenir par le déclenchement d’une notification d’alerte.'"
                        :class="['ml-3',  errors.notificationDate ? 'text-error' : null]"
                        :style="{ cursor: 'pointer' }"
                      />
                    </label>
                    <Calendar
                      v-model="form.notificationDate"
                      show-icon
                      placeholder="Date de prévenance (notification)"
                      :class="['w-full', errors.notificationDate ? 'calendar-error' : null, isNotificationDateDisabled ? 'calendar-disabled' : null]"
                      :disabled="isNotificationDateDisabled"
                      dateFormat="dd/mm/yy"
                      showButtonBar
                    />
                    <div v-show="errors.notificationDate" :class="['absolute', 'text-sm']" :style="{ color: 'red', bottom: '-8px' }">
                      {{ errors.notificationDate }}
                    </div>
                  </div>

                  <!-- Reconduction tacite -->
                  <div :class="['flex', 'flex-col', 'relative']">
                    <label
                      for="automaticRenewal"
                      :class="['form-label', 'font-bold', errors.automaticRenewal ? 'text-error' : null]"
                    >
                      Reconduction tacite<span v-if="context !== 'VIEW'">*</span>
                    </label>
                    <span v-if="context === 'VIEW'">
                      {{ form.automaticRenewal ? 'Oui' : 'Non' }}
                    </span>
                    <div v-else :class="'relative'">
                      <div :class="['flex', 'flex-row']">
                        <div :class="['p-1', 'pr-5', 'flex', 'flex-row', 'items-center']">
                          <input
                            :value="true"
                            :id="true"
                            v-model="form.automaticRenewal"
                            name="radio__automaticRenewal"
                            type="radio"
                          />
                          <label :for="'radio__mercurialNetwork'" :class="['px-1']">
                            Oui
                          </label>
                        </div>
                        <div :class="['p-1', 'pl-5', 'flex', 'flex-row', 'items-center']">
                          <input
                            :value="false"
                            :id="false"
                            v-model="form.automaticRenewal"
                            name="radio__automaticRenewal"
                            type="radio"
                          />
                          <label :for="'radio__automaticRenewal'" :class="['px-1']">
                            Non
                          </label>
                        </div>
                      </div>
                    </div>
                    <div v-show="errors.automaticRenewal" :class="['absolute', 'text-sm']" :style="{ color: 'red', bottom: '-12px' }">
                      {{ errors.automaticRenewal }}
                    </div>
                  </div>

                  <!-- Durée de reconduction -->
                  <div v-if="form.automaticRenewal === true" class="flex flex-col relative">
                    <label
                      for="renewalDuration"
                      :class="['form-label', 'font-bold', errors.renewalDuration ? 'text-error' : null]"
                    >
                      Durée de reconduction en mois*
                    </label>
                    <input
                      id="renewalDuration"
                      name="renewalDuration"
                      v-model="form.renewalDuration"
                      type="number"
                      :class="[
                        'form-input',
                        context === 'VIEW' ? 'disabled' : null,
                        errors.renewalDuration ? 'error' : null
                      ]"
                      :disabled="context === 'VIEW'"
                    />
                    <div v-show="errors.renewalDuration" :class="['absolute', 'text-sm']" :style="{ color: 'red', bottom: '-8px' }">
                      {{ errors.renewalDuration }}
                    </div>
                  </div>
                </fieldset>
                <br><br>
              </section>

              <!-- DÉTAILS DU CONTRAT -->
              <section>
                <header
                  class="card-header flex flex-col px-8 md:flex-row items-center p-6 justify-center"
                  :style="{ boxShadow: '0 0 0 1px rgba(0, 0, 0, 0.05)' }"
                >
                  <h2
                    :class="['m-0-auto', 'text-secondary', 'font-bold']"
                  >
                    Détails du contrat
                  </h2>
                </header>
                <fieldset class="grid grid-cols-1 md:grid-cols-2 gap-8 mt-4 mb-10 mx-8">
                  <!-- Montant du contrat en euros -->
                  <div class="flex flex-col relative">
                    <label
                      for="yearAmount"
                      :class="['form-label', 'font-bold', errors.yearAmount ? 'text-error' : null]"
                    >
                      Montant du contrat en euros
                    </label>
                    <input
                      id="yearAmount"
                      name="yearAmount"
                      v-model="form.yearAmount"
                      type="number"
                      step="0.01"
                      min="0"
                      inputmode="decimal"
                      :class="[
                        'form-input',
                        context === 'VIEW' ? 'disabled' : null,
                        errors.yearAmount ? 'error' : null
                      ]"
                      :disabled="context === 'VIEW'"
                      @input="checkPositiveNull"
                    />
                    <div v-show="errors.yearAmount" :class="['absolute', 'text-sm']" :style="{ color: 'red', bottom: '-8px' }">
                      {{ errors.yearAmount }}
                    </div>
                  </div>

                  <!-- Devise -->
                  <div class="flex flex-col">
                    <label
                      for="currency"
                      :class="['form-label', 'font-bold', errors.currency ? 'text-error' : null]"
                    >
                      Devise
                    </label>
                    <m-select
                      :options="dropdownCurrencies"
                      :disabled="context === 'VIEW'"
                      :error="errors.currency"
                      :ref="'currency'"
                      :value-key="'id'"
                      v-model="form.currency"
                      @input="handleCurrency"
                    />
                  </div>

                  <!-- Clause de révision + Réference interne -->
                  <div :class="['flex', 'flex-col', 'justify-between']">
                    <!-- Clause de révision -->
                    <div class="flex flex-col relative">
                      <label
                        for="pricesRevisionClause"
                        :class="['form-label', 'font-bold', errors.pricesRevisionClause ? 'text-error' : null]"
                      >
                        Ce contrat inclut-il une clause de révision des prix ?
                      </label>
                      <span v-if="context === 'VIEW'">
                        {{ form.pricesRevisionClause ? 'Oui' : 'Non' }}
                      </span>
                      <div v-else :class="'relative'">
                        <div :class="['flex', 'flex-row']">
                          <div :class="['p-1', 'pr-5', 'flex', 'flex-row', 'items-center']">
                            <input
                              :value="true"
                              :id="true"
                              v-model="form.pricesRevisionClause"
                              name="radio__pricesRevisionClause"
                              type="radio"
                            />
                            <label :for="'radio__pricesRevisionClause'" :class="['px-1']">
                              Oui
                            </label>
                          </div>
                          <div :class="['p-1', 'pl-5', 'flex', 'flex-row', 'items-center']">
                            <input
                              :value="false"
                              :id="false"
                              v-model="form.pricesRevisionClause"
                              name="radio__pricesRevisionClause"
                              type="radio"
                            />
                            <label :for="'radio__pricesRevisionClause'" :class="['px-1']">
                              Non
                            </label>
                          </div>
                        </div>
                      </div>
                      <div v-show="errors.pricesRevisionClause" :class="['absolute', 'text-sm']" :style="{ color: 'red', bottom: '-12px' }">
                        {{ errors.pricesRevisionClause }}
                      </div>
                    </div>
                    <!-- Référence interne -->
                    <div class="flex flex-col">
                      <label
                        for="internalRef"
                        :class="['form-label', 'font-bold', errors.internalRef ? 'text-error' : null]"
                      >
                        Référence interne
                      </label>
                      <input
                        id="internalRef"
                        name="internalRef"
                        v-model="form.internalRef"
                        type="text"
                        :class="[
                          'form-input',
                          context === 'VIEW' ? 'disabled' : null,
                          errors.internalRef ? 'error' : null
                        ]"
                        :disabled="context === 'VIEW'"
                      />
                    </div>
                  </div>

                  <!-- Note -->
                  <div class="flex flex-col relative">
                    <label
                      for="note"
                      :class="['form-label', 'font-bold', errors.note ? 'text-error' : null]"
                    >
                      Note
                    </label>
                    <textarea
                      id="note"
                      name="note"
                      v-model="form.note"
                      rows="5"
                      type="textfield"
                      :class="[
                        'form-input',
                        context === 'VIEW' ? 'disabled' : null,
                        errors.note ? 'error' : null
                      ]"
                      :disabled="context === 'VIEW'"
                    >
                    </textarea>
                    <div v-show="errors.note" :class="['absolute', 'text-sm']" :style="{ color: 'red', bottom: '-8px' }">
                      {{ errors.note }}
                    </div>
                  </div>
                </fieldset>

                <caption v-show="context !== 'VIEW'" :class="['flex', 'flex-row', 'justify-center', 'items-center', context === 'UPDATE-CONTRACT' || context === 'UPDATE-AMENDMENT' ? 'mt-10' : 'my-10']">
                  * Champs obligatoires
                </caption>

                <div v-if="context && context.startsWith('CREATE')" :class="['flex', 'flex-row', 'justify-center', 'items-center', 'mb-10']">
                  <input
                    class="cursor-pointer mx-3"
                    type="checkbox"
                    v-model="isMercurialAuthorized"
                  >
                  <p :class="['font-bold']">
                    J'autorise Mercurial à consulter ces informations, à l'exception de l'ensemble des documents attachés
                  </p>

                  <icon
                    :style="{ marginLeft: '10px', cursor: 'pointer' }"
                    icon="infos-primary-bluer"
                    size="md"
                    v-tooltip.top="'En cochant la case, vous autorisez MERCURIAL à accéder à la liste de vos contrats, sans que les documents attachés soient consultables.'"
                  />
                </div>

                <div v-if="context && context.startsWith('CREATE')" class="flex justify-center my-8">
                  <button
                    :class="['btn', 'btn-secondary', !isMercurialAuthorized ? 'disabled' : null]"
                    @click="onSubmitContract('CREATE')"
                  >
                    VALIDER
                  </button>
                </div>
              </section>
            </form>
          </card>
        </div>
      </div>
    </div>

    <ContractsModalParent :isParentModalOpen.sync="isParentModalOpen" :id="refId" @closeModalParent="isParentModalOpen = false" />
    <ContractsModalHistory v-if="isHistoryModalOpen" @close="isHistoryModalOpen = false" />
    <ContractsModalDelete :is-open.sync="isDeleteModalOpen" />
    <ContractsModalUpdate :is-open.sync="isUpdateModalOpen" @submit="updateContract" />
    <ContractsModalHalted :is-open.sync="isHaltedModalOpen" :original-end-date="form.endDate" @submit="updateContract" />
    <ContractsModalArchived :is-open.sync="isArchivedModalOpen" @submit="updateContract" />
    <ContractsModalCollaborator
      :is-open.sync="isCollaboratorModalOpen"
      :collaborator="currentUpdateCollaborator"
      @submit="updateCollaborator"
      @closeModalCollaborator="isCollaboratorModalOpen = false"
    />
    <ContractsModalPursuit
      :renewalDuration="form.renewalDuration"
      :isPursuitModalOpen.sync="isPursuitModalOpen"
      :id="refId"
      :parentContract="form.title"
      @submit="pursuitContract"
      @closeModalPursuit="isPursuitModalOpen = false"
    />

    <footer v-if="context === 'UPDATE-CONTRACT' || context === 'UPDATE-AMENDMENT'" :class="['footer']">
      <nav>
        <button
          class="btn btn-outline--primary-bluer suppliers"
          @click="resetStateForm"
        >
          Annuler
        </button>
        <button
          :class="['btn', 'btn-secondary', !isFormUpdated ? 'disabled' : null]"
          @click="onSubmitContract('UPDATE')"
          @submit=""
        >
          Enregistrer
        </button>
      </nav>
    </footer>

  </div>
</template>

<script>
import ContractsModalParent from "@/components/customs/contracts/ModalParent.vue";
import ContractsModalPursuit from "@/components/customs/contracts/ModalPursuit.vue";
import ContractsModalHistory from "@/components/customs/contracts/ModalHistory.vue";
import ContractsModalDelete from "@/components/customs/contracts/ModalDelete.vue";
import ContractsModalHalted from "@/components/customs/contracts/ModalHalted.vue";
import ContractsModalArchived from "@/components/customs/contracts/ModalArchived.vue";
import ContractsModalAddConfirm from "@/components/customs/contracts/ModalAddConfirm.vue";
import ContractsModalUpdate from "@/components/customs/contracts/ModalUpdate.vue";
import ContractsModalCollaborator from "@/components/customs/contracts/ModalCollaborator.vue"
import Card from '@/components/commons/Card.vue'
import $axios from "@/axios";
import MSelect from '@/components/commons/Select.vue'
import SelectFilter from '@/components/commons/SelectFilter.vue'
import Autosuggest from "@/components/commons/Autosuggest.vue";
import Uploader from "@/components/commons/Uploader.vue";

export default {
  components: {
    ContractsModalParent,
    ContractsModalPursuit,
    ContractsModalHistory,
    ContractsModalDelete,
    ContractsModalHalted,
    ContractsModalArchived,
    ContractsModalAddConfirm,
    ContractsModalUpdate,
    ContractsModalCollaborator,
    Card,
    MSelect,
    SelectFilter,
    Autosuggest,
    Uploader
  },

  props: {
    refId: {
      type: Number | String | null,
      default: null
    }
  },

  data() {
    return {
      form: {
        automaticRenewal: null,
        collaborators: [],
        contractDocuments: [],
        contractNature: null,
        contractStatus: null,
        currency: null,
        description : null,
        endDate: null,
        internalRef: null,
        masterContract: null,
        mercurialNetwork: null,
        note: null,
        revisionNote: null,
        noticeDuration: null,
        notificationDate: null,
        parentContract: null,
        pricesRevisionClause: false,
        privateAttachments: [],
        purchaseCategory: null,
        renewalDuration: null,
        startDate: null,
        supplier: null,
        supplierText: null,
        title: null,
        yearAmount: null,
      },
      header: {
        title: null,
        author: null,
        amendment: false,
        hasParent: false,
        parentContractTitle: null,
        hasChildrens: true
      },
      errors: {
        automaticRenewal: null,
        collaborators: null,
        contractNature: null,
        contractStatus: null,
        currency: null,
        description : null,
        endDate: null,
        internalRef: null,
        masterContract: null,
        mercurialNetwork: null,
        note: null,
        revisionNote: null,
        noticeDuration: null,
        notificationDate: null,
        parentContract: null,
        pricesRevisionClause: null,
        purchaseCategory: null,
        renewalDuration: null,
        startDate: null,
        supplier: null,
        title: null,
        yearAmount: null,
        contractDocuments: null
      },
      isErrorsOnForm: false,
      isParentModalOpen: false,
      isCollaboratorModalOpen: false,
      isPursuitModalOpen: false,
      isHistoryModalOpen: false,
      isDeleteModalOpen: false,
      isAddConfirmModalOpen: false,
      isUpdateModalOpen: false,
      isHaltedModalOpen: false,
      isArchivedModalOpen: false,
      originalContractStatus: null,
      originalCollaborators: [],
      originalForm: {},
      dropdownContractNatures: [],
      dropdownContracts: [],
      dropdownCurrencies: [],
      dropdownPurchaseCategories: [],
      dropdownSuppliers: [],
      isMercurialAuthorized: true,
      currentUpdateCollaborator: null,
      context: null, // ['CREATE-CONTRACT'|'CREATE-AMENDMENT'|'VIEW'|'UPDATE-CONTRACT'|'UPDATE-AMENDMENT']
    }
  },

  computed: {
    userRole(){
      return this.$store.getters.userRole;
    },

    userId(){
      return this.$store.state.authentication.user.id
    },

    mercurialNetwork(){
      return this.form.mercurialNetwork
    },

    hasWritingRights(){
      return this.form?.collaborators?.some(
        collaborator =>
          collaborator.id === this.userId && collaborator.permission === "write"
      ) || this.header.authorId === this.userId;
    },

    isStartDateDisabled(){
      return this.context === 'VIEW'
        || this.form.contractStatus === 'active'
        || this.form.contractStatus === 'renew'
        || this.form.contractStatus === 'halted'
        || this.form.contractStatus === 'archived'
    },

    isEndDateDisabled(){
      return this.context === 'VIEW'
        || this.form.contractStatus === 'halted'
        || this.form.contractStatus === 'archived'
    },

    isNotificationDateDisabled(){
      return this.context === 'VIEW'
        || this.form.contractStatus === 'halted'
        || this.form.contractStatus === 'archived'
    },

    isNoticeDurationDisabled(){
      return this.context === 'VIEW'
        || this.form.contractStatus === 'halted'
        || this.form.contractStatus === 'archived'
    },

    parentContractLabel(){
      return this.dropdownContracts.find(
        item => item.id === this.form.parentContract)?.label
    },

    /**
     * dropdownStatuses
     * @return {[{ label: 'Brouillon', value: 'draft'},{ label: 'Validé', value: 'validated'},{ label: 'Actif', value: 'active'},{ label: 'Reconduit', value: 'renew'},{ label: 'Arrêté', value: 'halted'},{ label: 'Archivé', value: 'archoved'}]}
     */
    dropdownStatuses(){
      return this.context === 'CREATE-CONTRACT' || this.context === 'CREATE-AMENDMENT'
        ? [
          { label: 'Brouillon', value: 'draft'},
          { label: 'Validé', value: 'validated'},
        ]
        : this.originalContractStatus === 'draft'
        ? [
          { label: 'Brouillon', value: 'draft'},
          { label: 'Validé', value: 'validated'},
          { label: 'Archivé', value: 'archived'}
        ]
        : this.originalContractStatus === 'validated'
        ? [
          { label: 'Validé', value: 'validated'},
          { label: 'Arrêté', value: 'halted'},
          { label: 'Archivé', value: 'archived'}
        ]
        : this.originalContractStatus === 'active'
        ? [
          { label: 'Actif', value: 'active'},
          { label: 'Arrêté', value: 'halted'},
          { label: 'Archivé', value: 'archived'}
        ]
        : this.originalContractStatus === 'renew'
        ? [
          { label: 'Reconduit', value: 'renew'},
          { label: 'Arrêté', value: 'halted'},
          { label: 'Archivé', value: 'archived'}
        ]
        : this.originalContractStatus === 'halted'
        ? [
          { label: 'Arrêté', value: 'halted'},
          { label: 'Archivé', value: 'archived'}
        ]
        : this.originalContractStatus === 'archived'
        ? [
          { label: 'Archivé', value: 'archived'}
        ] : []
    },

    dropdownCollaborators(){
      return this.originalCollaborators?.filter(oc => {
        return !this.form.collaborators?.some(fc => fc.id === oc.id);
      });
    },

    isFormUpdated(){
      return JSON.stringify(this.form) !== JSON.stringify(this.originalForm);
    },

    supplierLabel() {
      return this.dropdownSuppliers.find((supplier) => supplier.id === this.form.supplier)?.label
    },
  },

  async mounted() {
    this.context = this.$route.name === 'contract-detail'
      ? 'VIEW'
      : !this.refId
      ? 'CREATE-CONTRACT'
      : 'CREATE-AMENDMENT'

    if (this.context !== 'CREATE-CONTRACT'){
      this.fetchContract().then(() => {
        this.presetContract()
      }).then(() => {
        // Clone object to keep in memory!
        this.originalForm = JSON.parse(JSON.stringify({...this.form}));
      })
    }
  },

  watch: {
    context(newVal, oldVal) {
      this.getResourcesDropdowns()
      if (newVal === oldVal || newVal !== 'VIEW') return;
      this.eraseErrors()
    },
    mercurialNetwork(newVal, oldVal){
      if (newVal === oldVal) return;

      this.form.supplier = null;
      this.form.supplierText = null;
    }
  },

  methods: {
    /**
     * Handlers (update des valeurs du form)
     * @return {void}
     */
    handlerParentContract(value){
      this.form.parentContract = value
    },

    handlerSupplierText(value){
      this.form.supplierText = value
      this.form.supplier = null
    },

    handlerSupplierItem(value){
      this.form.supplier = value
      this.form.supplierText = null
    },

    handleContractStatus(value){
      this.form.contractStatus = value
    },

    handleCurrency(value){
      this.form.currency = value
    },

    handleContractNature(value){
      this.form.contractNature = value
    },

    handlePurchaseCategory(value){
      this.form.purchaseCategory = value
    },

    addContractDocument(file){
      this.form.contractDocuments.push({
        fileId: file.data.fid[0].value,
        filename: file.data.filename[0].value,
      })

      this.errors.contractDocuments = null;
    },

    addPrivateAttachment(file){
      this.form.privateAttachments.push({
        fileId: file.data.fid[0].value,
        filename: file.data.filename[0].value,
      })
    },

    checkPositive(event) {
      const value = parseFloat(event.target.value);
      if (value < 0) {
        this.form.noticeDuration = 0; // Remet la valeur à zéro si elle est négative
      }
    },

    checkPositiveNull(event) {
      const value = parseFloat(event.target.value);
      if (value < 0) {
        this.form.yearAmount = null; // Remet la valeur à zéro si elle est négative
      }
    },

    /**
     * Erase Errors method
     * @return {void}
     */
    eraseErrors(){
      this.errors = {
        automaticRenewal: null,
        collaborators: null,
        contractNature: null,
        contractStatus: null,
        currency: null,
        description : null,
        endDate: null,
        internalRef: null,
        masterContract: null,
        mercurialNetwork: null,
        note: null,
        revisionNote: null,
        noticeDuration: null,
        notificationDate: null,
        parentContract: null,
        pricesRevisionClause: null,
        purchaseCategory: null,
        renewalDuration: null,
        startDate: null,
        supplier: null,
        title: null,
        yearAmount: null,
      }

      this.isErrorsOnForm = false;
    },

    /**
     * Check Errors method
     * @return {void}
     */
    async checkErrors(ctx){
      this.eraseErrors();
      try {
        const payload = this.parseForm(null)
        if (ctx === 'CREATE') {
          await $axios.post(
            `${process.env.VUE_APP_API_VERSION}/contract/check`,
            { ...payload },
            {
              headers: {
                'X-CSRF-Token': this.$store.state.authentication.xcsrf_token
              },
            }
          )
        } else if (ctx === 'UPDATE') {
          await $axios.patch(
            `${process.env.VUE_APP_API_VERSION}/contract/${ this.refId }/check`,
            { ...payload },
            {
              headers: {
                'X-CSRF-Token': this.$store.state.authentication.xcsrf_token
              },
            }
          )
        }

      } catch (error) {
        if (error.response?.status === 400) {
          error.response?.data?.reverse().forEach(e => {
            this.errors[e?.field] = e.message;
          });
          this.isErrorsOnForm = true

          this.$forceUpdate();

        } else {
          this.$toast.add({
            severity: 'error',
            summary: 'Erreur',
            detail: error.response?.data?.message || 'Une erreur est survenue',
            life: 5000,
          });
        }
      }
    },

    /**
     * TODO: install date fns ou equivalent omgggg
     * @return {any}
     */
    formatDate(input) {
      // Vérifie si l'entrée est un objet Date
      if (input instanceof Date && !isNaN(input)) {
        return input.toLocaleDateString('fr-CA');
      }
      // Vérifie si l'entrée est une chaîne de type yyyy-mm-dd
      else if (/^\d{4}-\d{2}-\d{2}$/.test(input)) {
        return input; // Déjà au format yyyy-mm-dd
      }
      // Vérifie si l'entrée est une chaîne de type dd/mm/yyyy
      else if (/^\d{2}\/\d{2}\/\d{4}$/.test(input)) {
          const [day, month, year] = input.split('/');
          return `${year}-${month}-${day}`; // Reformate en yyyy-mm-dd
      }
    },

    /**
     * REset form to previous State
     * @param {any} value
     * @return {void}
     */
    resetStateForm(){
      this.form = {...this.originalForm}
      this.toggleEditCtx('VIEW')
    },

    /**
     * Update Collabs
     * @param {any} value
     * @return {void}
     */
    addCollaborator(value){
      const ref = this.originalCollaborators.find(item => item.id === value);
      const collaborator = {
        id: ref?.id,
        name: ref?.label,
        permission: 'read',
      }
      this.form.collaborators.push(collaborator)
      this.openPopinCollaboratorPermission(collaborator)
    },

    /**
     * Toggle Edit Context
     * @param {'UPDATE-CONTRACT'|'UPDATE-AMENDMENT'|'VIEW'|null} forceContext (Si l'action est précisée, on la set, sinon on applique l'action inverse de celle courante)
     * @return {void}
     */
    toggleEditCtx(forceContext = null){
      if (forceContext === "UPDATE-AMENDMENT" || forceContext === "UPDATE-CONTRACT"){
        if (this.$refs?.parentContract && this.parentContractLabel){
          this.$refs.parentContract.query = this.parentContractLabel
        }
      }
      if (forceContext){
        this.context = forceContext;

        return;
      }

      this.context = this.context === 'UPDATE-CONTRACT' || context === 'UPDATE-AMENDMENT'
        ? 'VIEW'
        : this.header.amendment
        ? 'UPDATE-AMENDMENT'
        : 'UPDATE-CONTRACT'
    },

    /**
     * Toggle Popin Collaborator Permission
     * @return {void}
     */
    openPopinCollaboratorPermission(collaborator){
      this.isCollaboratorModalOpen = true;
      this.currentUpdateCollaborator = collaborator;
    },

    /**
     * Remove attachment
     * @return {void}
     */
    deleteItem(ctx, target){
      if (ctx === 'contractDocuments'){
        this.form.contractDocuments = this.form.contractDocuments.filter((item) => {
          return item.fileId !== target.fileId;
        });
      } else if (ctx === 'privateAttachments'){
        this.form.privateAttachments = this.form.privateAttachments.filter((item) => {
          return item.fileId !== target.fileId;
        });
      } else {
        this.form.collaborators = this.form.collaborators.filter((item) => {
          return item.id !== target.id;
        });
      }
    },

    /**
     * Remove attachment method
     * @param {id} any
     * @return {void}
     */
    removeAttachment(id) {
      const transactions = JSON.parse(
        JSON.stringify(this.$store.state.banking.transactions)
      )
      const transaction = JSON.parse(JSON.stringify(this.transaction))

      const indexUpload = transaction.attachments.findIndex(
        (obj) => String(obj.target_id) === String(id)
      )
      const indexTransaction = transactions.findIndex(
        (item) => String(item.id) === String(transaction.id)
      )

      if (indexUpload !== -1) {
        transaction.attachments.splice(indexUpload, 1)
      }

      if (indexTransaction !== -1) {
        transactions[indexTransaction].attachments = transaction.attachments
      }

      this.transaction = transaction
      this.$store.commit('banking/SET_TRANSACTIONS', transactions)
    },

    /**
     * preset Contract function
     * @return {void}
     */
    presetContract(){
      this.$refs.contractStatus.selectedValue = this.form.contractStatus

      this.$refs.contractNature.selectedValue = this.form.contractNature

      this.$refs.currency.selectedValue = this.form.currency

      this.$refs.purchaseCategory.selectedValue = this.form.purchaseCategory

      if (this.$refs?.supplier){
        this.$refs.supplier.query = this.supplierLabel
      }

      this.originalContractStatus = this.form.contractStatus

      if (this.form.startDate) {
        const startDate = new Date(this.form.startDate);
        const day = String(startDate.getDate()).padStart(2, '0');
        const month = String(startDate.getMonth() + 1).padStart(2, '0');
        const year = startDate.getFullYear();
        this.form.startDate = `${day}/${month}/${year}`;
      }

      if (this.form.endDate) {
        const endDate = new Date(this.form.endDate);
        const day = String(endDate.getDate()).padStart(2, '0');
        const month = String(endDate.getMonth() + 1).padStart(2, '0');
        const year = endDate.getFullYear();
        this.form.endDate = `${day}/${month}/${year}`;
      }

      if (this.form.notificationDate ) {
        const notificationDate = new Date(this.form.notificationDate);
        const day = String(notificationDate.getDate()).padStart(2, '0');
        const month = String(notificationDate.getMonth() + 1).padStart(2, '0');
        const year = notificationDate.getFullYear();
        this.form.notificationDate = `${day}/${month}/${year}`;
      }

      if (this.context === 'CREATE-AMENDMENT'){
        this.$refs.contractStatus.selectedValue = null
        this.form.contractStatus = null
        this.form.privateAttachments = []

        this.form.contractDocuments = []

        this.form.parentContract = this.refId

        this.form.masterContract = false;

        if (this.$refs?.parentContract){
          this.$refs.parentContract.query = this.form.title
        }
      }
    },

    /**
     * fetch Contract function
     * @return {void}
     */
    async fetchContract(){
      if (!this.refId) return;

      try {
        const response = await $axios.get(`${process.env.VUE_APP_API_VERSION}/contract/${this.refId}`,
          {
          headers: {
            'X-CSRF-Token': this.$store.state.authentication.xcsrf_token
          }
        })
        this.form = response.data?.form
        this.header = response.data?.header

      } catch (error) {
        this.$toast.add({
          severity: 'error',
          summary: 'Erreur',
          detail: 'Impossible de récupérer le contrat.',
          life: 5000,
        });
      }
    },

    /**
     * get Resources dropdowns
     * @return {void}
     */
    async getResourcesDropdowns(){
      try {
        const response = await $axios.get(
          `${process.env.VUE_APP_API_VERSION}/contract/autocompletes`,
          {
            params: {
              types: 'currencies,contracts,suppliers,collaborators,contract_natures,purchase_categories',
              context: this.context,
            }
          }
        )

        this.originalCollaborators = Object.keys(response.data.collaborators).map((key) => { return response.data.collaborators[key]; });

        this.dropdownContractNatures = Object.keys(response.data.contract_natures).map((key) => { return response.data.contract_natures[key]; });

        this.dropdownPurchaseCategories = Object.keys(response.data.purchase_categories).map((key) => { return response.data.purchase_categories[key]; });

        if (this.context?.startsWith('UPDATE')) {
          this.dropdownContracts = Object.keys(response.data.contracts).filter((key) => key !== this.refId?.toString()).map((key) => { return response.data.contracts[key]; })
        } else {
          this.dropdownContracts = Object.keys(response.data.contracts).map((key) => { return response.data.contracts[key]; })
        }

        this.dropdownCurrencies = Object.keys(response.data.currencies).map((key) => { return response.data.currencies[key]; })

        this.dropdownSuppliers = Object.keys(response.data.suppliers).map((key) => { return response.data.suppliers[key]; })
      } catch (error) {
        this.$toast.add({
          severity: 'error',
          summary: 'Erreur',
          detail: 'Erreur dans la récupération du formulaire. Veuillez contacter un administrateur.',
          life: 5000,
        });
      }
    },

    /**
     * Donwload File Helper
     * @param {any} slug
     * @return {void}
     */
    async downloadFile(slug){
      try {
        const blob = await $axios.get(`${process.env.VUE_APP_API_VERSION}/documents/${slug}/file`, {
          responseType: 'blob'
        })

        const filename = blob.headers['content-disposition'].split('filename=')[1].replaceAll('"', '')
        const url = window.URL.createObjectURL(new Blob([blob.data]));
        const link = document.createElement('a');

        link.href = url;
        link.setAttribute('download', filename);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link)
      } catch (error) {
        this.$toast.add({
          severity: 'error',
          summary: 'Erreur',
          detail: error.response?.data?.message || 'Une erreur est survenue',
          life: 5000,
        });
      }
    },

    /**
     * Create contract function
     * @return {void}
     */
    async createContract() {
      try {
        const payload = this.parseForm(null)
        if (this.context === 'CREATE-AMENDMENT') {
          payload.amendment = true
        }

        await $axios.post(
          `${process.env.VUE_APP_API_VERSION}/contract`, {
            ...payload,
          },{
            headers: {
              'X-CSRF-Token': this.$store.state.authentication.xcsrf_token
            },
          }).then(() => {
            this.$router.push({ name: 'contracts' })
          })

          this.$toast.add({
            severity: 'success',
            summary: 'Succès',
            detail: 'Le contrat a bien été créé.',
            life: 5000,
          });

      } catch (error) {
        error?.response?.data?.forEach(e => {
          this.errors[e?.field] = e.message;
        });
        this.$toast.add({
          severity: 'error',
          summary: 'Erreur',
          detail: 'Impossible de créer le contrat.',
          life: 5000,
        });
      }
    },

    /**
     * Update contract function
     * @param {string} revisionNote
     * @param {string} endDate
     * @param {string} context
     * @return {void}
     */
    async updateContract(revisionNote, endDate = null, context = 'DEFAULT') {
      try {
        const payload = this.parseForm(revisionNote, null, endDate)

        await $axios.patch(
          `${process.env.VUE_APP_API_VERSION}/contract/${this.refId}`, {
            ...payload,
          },{
            headers: {
              'X-CSRF-Token': this.$store.state.authentication.xcsrf_token
            },
          }).then(() => {
            this.$router.push(
              { name: 'contract-detail', params: { id: this.refId }}
            )
          })

          this.$toast.add({
            severity: 'success',
            summary: 'Succès',
            detail: 'Le contrat a bien été mis à jour.',
            life: 5000,
          });

          if (this.form.contractStatus === 'archived'){
            this.originalContractStatus = 'archived'
          }

          this.context = 'VIEW';

      } catch (error) {
        console.log(error)
        error?.response?.data?.forEach(e => {
          this.errors[e?.field] = e.message;
        });

        this.$toast.add({
          severity: 'error',
          summary: 'Erreur',
          detail: 'Impossible de modifier le contrat.',
          life: 5000,
        });
      } finally {
        if (context !== 'HALTED') return;
        
        this.fetchContract().then(() => {
          this.presetContract()
        }).then(() => {
          // Clone object to keep in memory!
          this.originalForm = JSON.parse(JSON.stringify({...this.form}));
        })
      }
    },

    /**
     * Pursuit contract function
     * @param {number} durationMonths
     * @return {void}
     */
    async pursuitContract(durationMonths) {
      try {
        const payload = this.parseForm('Reconduction du contrat', durationMonths)
        payload.forceRenew = true

        await $axios.patch(
          `${process.env.VUE_APP_API_VERSION}/contract/${this.refId}`, {
            ...payload,
          },{
            headers: {
              'X-CSRF-Token': this.$store.state.authentication.xcsrf_token
            },
          }).then(() => {
            this.$router.push(
              { name: 'contract-detail', params: { id: this.refId }}
            )
          })

          this.$toast.add({
            severity: 'success',
            summary: 'Succès',
            detail: 'Le contrat a bien été reconduit.',
            life: 5000,
          });

      } catch (error) {
        error?.response?.data?.forEach(e => {
          this.errors[e?.field] = e.message;
        });

        this.$toast.add({
          severity: 'error',
          summary: 'Erreur',
          detail: 'Impossible de reconduire le contrat.',
          life: 5000,
        });
      } finally {
        this.fetchContract().then(() => {
          this.presetContract()
        }).then(() => {
          // Clone object to keep in memory!
          this.originalForm = JSON.parse(JSON.stringify({...this.form}));
        })
      }
    },

    updateCollaborator(id, permission){
      const collab = this.form.collaborators.find(user => user.id === id)

      collab.permission = permission
    },

    /**
     * ParseForm contract function
     * @return {any}
     */
    parseForm(revisionNote, durationMonths, endDate){
      // . Parse for backend
      const payload = { ...this.form }

      if (durationMonths){
        payload.renewalDuration = durationMonths;
      }

      if (revisionNote){
        payload.revisionNote = revisionNote;
      }

      if (revisionNote === 'Reconduction du contrat'){
        payload.contractStatus = 'renew';
      }

      if (payload.noticeDuration){
        payload.noticeDuration  = parseInt(payload.noticeDuration);
      }

      if (payload.renewalDuration){
        payload.renewalDuration  = parseInt(payload.renewalDuration);
      }

      if (payload.yearAmount){
        payload.yearAmount  = parseFloat(parseFloat(payload.yearAmount).toFixed(2));
      }

      if (payload.contractDocuments){
        payload.contractDocuments = payload.contractDocuments.map(item => item.fileId.toString());
      }

      if (payload.privateAttachments){
        payload.privateAttachments = payload.privateAttachments.map(item => item.fileId.toString());
      }

      if (payload.startDate){
        payload.startDate = this.formatDate(payload.startDate);
      }

      if (endDate){
        payload.endDate = this.formatDate(endDate);
      } else if (payload.endDate){
        payload.endDate = this.formatDate(payload.endDate);
      }

      if (payload.notificationDate){
        payload.notificationDate = this.formatDate(payload.notificationDate);
      }

      if (payload.mercurialNetwork && this.userRole === 'subscriber'){
        payload.supplierText = null
      } else {
        payload.supplier = null
      }

      return payload
    },

    /**
     * Submit contract function
     * @param {'CREATE'|'UPDATE'} ctx
     * @return {void}
     */
    async onSubmitContract(ctx){
      // Check Errors
      await this.checkErrors(ctx)

      if (this.isErrorsOnForm){
        this.$toast.add({
          severity: 'error',
          summary: 'Erreur',
          detail: 'Veuillez corriger les erreurs dans le formulaire avant de valider.',
          life: 5000,
        });
        window.scrollTo({top: 0, behavior: 'smooth'});
        return
      }

      // Open Modal
      if (ctx === 'CREATE') {
        this.createContract()
      } else if (this.originalContractStatus !== this.form.contractStatus && this.form.contractStatus === 'halted'){
        this.isHaltedModalOpen = true
      } else if (this.originalContractStatus !== this.form.contractStatus && this.form.contractStatus === 'archived'){
        this.isArchivedModalOpen = true
      } else {
        this.isUpdateModalOpen = true
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.footer {
  width: 100%;
  background: white;
  height: 75px;
  position: fixed;
  bottom: 0;
  border: 1px solid #e0e0e0;

  nav {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    > * {
      margin-left: 10px;
      margin-right: 10px;
    }
  }
}
</style>
