import firebase from 'firebase/app';

const firebaseConfig = {
  apiKey: "AIzaSyD1FGiZonC91g1nukKbPrSmN8LM-SCECYY",
  authDomain: "notifications-mercurial.firebaseapp.com",
  projectId: "notifications-mercurial",
  storageBucket: "notifications-mercurial.firebasestorage.app",
  messagingSenderId: "1059365844317",
  appId: "1:1059365844317:web:c2fe58d5feac1cf87de4b4",
  measurementId: "G-KBZC6F20SC"
};

let messaging = null;

if (/iPad|iPhone|iPod/.test(navigator.userAgent)) {
  console.warn('Firebase Messaging n\'est pas supporté sur iOS.');
} else {
  try {
    console.log('Initialisation de Firebase...');
    firebase.initializeApp(firebaseConfig);

    import('firebase/firebase-messaging')
      .then(() => {
        console.log('Initialisation de Firebase Messaging...');
        messaging = firebase.messaging();
        console.log(messaging);
      })
      .catch((error) => {
        console.error('Erreur lors de l\'importation de Firebase Messaging :', error.message);
        messaging = null;
      });
  } catch (error) {
    console.error('Erreur lors de l\'initialisation de Firebase :', error.message);
  }
}

export default messaging;